<template>
    <div class="error">
        <h5header :name="$t('sign.desc1')" :right="true" />
        <div class="login-con">
           <img src="../../public/images/reward/error.png" alt="">
        </div>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
export default {
    components: {
        h5header
    }
}
</script>

<style lang="less" scoped>
.error {
    padding: 96px 0 0;

    .login-con {
        text-align: center;
        min-height: 80vh;
     img{
        width: 500px;
        margin-top: 60px;
     }
    }
}

@media (max-width: 1200px) {
    .error {
        padding: 108px 0;
       
    }
}

@media (max-width: 767px) {
    .error {
        padding:44px 0;
        .login-con{
            min-height: 10vh;
           padding: 0 16px;
            img{
                width: 100%;
                margin-top: 50px;
            }
        }
    }
}</style>